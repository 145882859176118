import './utils/serverSide/shims';
import './window';
import './polyfills';
import './controllers';
import './utils/pageTransitions';
import ReactDOM from 'react-dom';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { importComponent } from './utils/importComponent';
import canUseDOM from './utils/serverSide/canUseDOM';
const preloadComponentsForSSR = () => {
    if (canUseDOM)
        return;
    Object.keys(globalThis.Components).forEach(name => {
        if (!globalThis.Components[name].load)
            return;
        globalThis.Components[name].load();
    });
};
// Expose all components for React.NET (SSR)
globalThis.React = React;
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
globalThis.Components = {
    // Include in the main.js for performance
    // Lazyload only when used
    Content: importComponent('Content'),
    Overview: importComponent('Overview'),
    Carousel: importComponent('Carousel'),
    ProcessTool: importComponent('ProcessTool'),
    ProcessUploadMedia: importComponent('ProcessUploadMedia'),
    ManageUmbracoNode: importComponent('ManageUmbracoNode')
};
preloadComponentsForSSR();
